import {
  ready,
  addClass, removeClass,
} from "dom-boilerplate";

ready(function() {
  var editModal = document.querySelector('.modal#edit-project');

  var editProject = document.querySelector('a[href="#edit-project"]');
  if (editProject) {
    editProject.addEventListener('click', function() {
      addClass(editModal, 'is-active');
    }, false);

  }
  var closeEditProject = document.querySelector('#edit-project.modal button.delete');
  if (closeEditProject) closeEditProject.addEventListener('click', function() {
    removeClass(editModal, 'is-active');
  }, false);
});
