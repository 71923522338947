import {
  addClass,
  removeClass
} from "dom-boilerplate";

// So **.js.{haml,erb} files can use these functions
window.addClass = addClass
window.removeClass = removeClass

window.App = window.App || {}


