import {
  ready,
  addClass, removeClass,
} from "dom-boilerplate";

ready(function() {
  var editDonationModal = document.querySelector('.modal#edit-donation');

  var editDonation = document.querySelector('a[href="#edit-donation"]');
  if (editDonation) {
    editDonation.addEventListener('click', function() {
      addClass(editDonationModal, 'is-active');
    }, false);

  }
  var closeeditDonation = document.querySelector('#edit-donation.modal button.delete');
  if (closeeditDonation) closeeditDonation.addEventListener('click', function() {
    removeClass(editDonationModal, 'is-active');
  }, false);
});
