// TRANSFER FROM HERE
//
//x require rails-ujs DONE
// require activestorage TODO as needed
//x require lodash DONE in add_init_selectr
//x require local-time DONE
//x require ./polyfill_closest
//x require ./app
//x require ./selectr
//x require ./helpers
//x require ./public/payment_methods
//x require ./admin/admin_add_init_selectr
//x require ../trix
// require ../actiontext // blows up with Uncaught SyntaxError: Cannot use import statement outside a module
//x require_tree .
//x require_self

//require("../trix")
//require("../actiontext")

import Rails from '@rails/ujs'
window.Rails = Rails

import {
  ready,
  addClass, removeClass,
  GET
} from "dom-boilerplate";

ready(function() {

  window.App = window.App || {};

  window.App.appendScript = function(text) {
    // shamelessly stolen from rails-ujs
    var script = document.createElement('script');
    script.setAttribute('nonce', Rails.cspNonce());
    script.text = text;
    document.head.appendChild(script).parentNode.removeChild(script);
  };

  // TODO: include in wrapper for request call
  window.App.usePromiseText = function(promise, func) {
    if (promise && typeof promise.then === 'function') {
      return promise.then(function(response) {
        return response.text();
      }).then(function(data) {
        func(data);
      });
    }
  }

  // activate tabs
  document.querySelectorAll('.tabs').forEach(function(tabs) {
    var as = tabs.querySelectorAll('a');
    as.forEach(function(a) {
      var li = a.parentNode;
      a.addEventListener('click', function(event) {
        as.forEach(function(a) {
          var li = a.parentNode;
          var el = document.querySelector(a.getAttribute('href'));
          if (el) el.style.display = 'none';
          removeClass(li, 'is-active');
        })
        var el = document.querySelector(a.getAttribute('href'));
        addClass(li, 'is-active');
        if (el) { el.style.display = ''; }
      });
      //if (window.location.hash) {
      //}
      // for default if li.is-active
      if (hasClass(li, 'is-active')) trigger(a, 'click');
    });
  });

  // show file name on bulma inputs.
  document.querySelectorAll('.file.has-name').forEach(function(file) {
    var input = file.querySelector('input[type=file]');
    var name = file.querySelector('.file-name');
    if (input) input.addEventListener('change', function(event) {
      if (name) name.textContent = input.files[0].name;
    });
  });

  // change amount of entry to last two digits in verify microdeposits fields
  document.querySelectorAll('.verify-amt').forEach(function(input) {
    input.addEventListener('keydown', function(event) {
      var otherKeys = ["ArrowLeft", "ArrowRight", "Tab", "Backspace"]
      var numerals = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
      var acceptedNumerals = numerals.indexOf(event.key) >= 0;
      var acceptedOtherKeys = otherKeys.indexOf(event.key) >= 0;
      var lengthOk = input.value.length < 2;
      if (!acceptedOtherKeys) {
        if (!acceptedNumerals || !lengthOk) event.preventDefault();
      }
    });
  });

  document.querySelector('body').
    addEventListener('ajax:error', function(e) {
      if(e && e.detail && e.detail[2]) {
        var status = e.detail[2].status;
        if (status === 401 || status == 403) {
          window.location = "/" + status + ".html";
        }
      }
      console.log('Error', e);
    });


  // Modals
  window.App.initModal = function (modalId) {
    var modal = document.querySelector(`.modal#${modalId}`);
    var toggle = document.querySelector(`a[href="#${modalId}"]`);
    if (toggle && modal) {
      toggle.addEventListener('click', function() {
        addClass(modal, 'is-active');
      }, false);
    }
    var closeModal = document.querySelector(`.modal#${modalId} button.delete`);
    if (closeModal && modal) {
      closeModal.addEventListener('click', function() {
        removeClass(modal, 'is-active');
      }, false);
    }
  };

  var modalIds = ['returns', 'missing-txns'];
  modalIds.forEach(window.App.initModal);

  // Handle reprocess button on Batch#show
  window.App.queryButtonTimer = function(buttonId, timeout) {
    var button = document.querySelector(`#${buttonId}`);
    if (!button || button.getAttribute('disabled') != 'disabled') {
      return;
    }

    var url = button.getAttribute('data-url');

    function refresh(){
      var promise = GET(
        url,
        {
          headers: {
            'Content-Type': 'application/javascript',
            'Accept': 'application/javascript'
          }
        }
      );
      window.App.usePromiseText(promise, window.App.appendScript);
    };

    var intervalId = setInterval(refresh, timeout);
    button.setAttribute('data-interval-id', intervalId);
  };

  window.App.queryButtonTimer(
    'reprocess-batch-button',
    3000
  );
});
