import {
  ready,
  addClass, removeClass,
  GET
} from "dom-boilerplate";

ready(function () {

  GET('/client-tokens/braintree', {
    headers: {
      'Accept': 'text/plain',
      'Content-Type': 'text/plain'
    }
  }).then(function(response) {
    return response.text();
  }).then(function(data) {
    if (data) initBraintree(data);
  });


  // get the the key from the meta tag
  //var braintreeMeta = document.querySelector('meta[name=braintree]');
  //if (braintreeMeta) var braintreeKey = braintreeMeta.getAttribute('content');
  //if (braintreeKey) initBraintree(braintreeKey);

  // Create a Stripe client.
  var stripeMeta = document.querySelector('meta[name=stripe]');
  if (stripeMeta) var stripeKey = stripeMeta.getAttribute('content');
  if (stripeKey) initStripe(stripeKey);

  var achTabs = document.querySelectorAll('#ach .tabs');
  achTabs.forEach(function (tabs) {
    var tabLinks = tabs.querySelectorAll('a').forEach(function (a) {
      a.addEventListener('click', function (event) {
        var typeField = document.getElementById('token_type')
        typeField.value = event.target.getAttribute('data-token');
        var content = document.querySelector('#ach .tab-content');
        content.querySelectorAll('input, select').forEach(function (el) {
          el.disabled = true;
        });
        var selector = event.target.getAttribute('href');
        var selected = document.querySelector(selector);
        selected.querySelectorAll('input, select').forEach(function (el) {
          el.disabled = false;
        });
      });
    });
  });

});

function initStripe(key) {
  var stripe = Stripe(key);

  // Handle form submission.
  var form_pm = document.querySelector('form[data-processor^="stripe"]');
  if (form_pm) registerFormSubmitEvent(form_pm);

  // Create an instance of Elements.
  var elements = stripe.elements();

  // Custom styling can be passed to options when creating an Element.
  // (Note that this demo uses a wider set of styles than the guide below.)
  var style = {
    base: {
      color: '#32325d',
      lineHeight: '18px',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  // Create an instance of the card Element.
  var card = elements.create('card', {
    hidePostalCode: true,
    style: style
  });

  // Add an instance of the card Element into the `card-element` <div>.
  if (document.getElementById('card-element')) {
    card.mount('#card-element');
    // Handle real-time validation errors from the card Element.
    card.addEventListener('change', function (event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  // form_pm_ba payment-method-bank-account is only used in the admin
  // interface on the add bank account modal/form
  var form_pm_ba = document.getElementById('payment-method-bank-account');
  if (form_pm_ba) registerFormSubmitEvent(form_pm_ba);


  function registerFormSubmitEvent(form) {
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      console.log('User submit.');
      var btn = form.querySelector('input[type=submit]');
      btn.disabled = true;
      addClass(btn, 'is-loading');

      console.log('Button disabled.');

      if (form.getAttribute('data-type') == 'card') {
        console.log('User updating card.');
        var data = document.querySelectorAll('[data-card]').
          reduce(function (accumulator, el) {
            accumulator[el.getAttribute('data-card')] = el.value;
            return accumulator;
          }, {
            country: 'us',
            currency: 'usd'
          });
        console.log(data);
        stripe.createToken(card, data).then(function (result) {
          if (result.error) {
            // Inform the user if there was an error.
            var errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
            btn.disabled = false;
            removeClass(btn, 'is-loading');
          } else {
            console.log(result);
            // Send the token to your server.
            stripeTokenHandler(form, result.token);
          }
        });
      } else {
        console.log('User updating bank account.');
        var typeField = document.getElementById('token_type');
        if (typeField.value === 'stripe') {
          console.log('User selected Stripe.');
          var data = document.querySelectorAll('[data-bank-account]').
            reduce(function (accumulator, el) {
              accumulator[el.getAttribute('data-bank-account')] = el.value;
              return accumulator;
            }, {
              country: 'us',
              currency: 'usd'
            });

          stripe.createToken('bank_account', data).then(function (result) {
            if (result.error) {
              console.log(result.error);
              // Inform the user if there was an error.
              var errorElement = document.getElementById('bank-account-errors');
              errorElement.textContent = result.error.message;
              btn.disabled = false;
              removeClass(btn, 'is-loading');
              form.querySelectorAll('input[data-local]').forEach(function (el) {
                el.disabled = false;
              });
            } else {
              console.log(result);
              // Clear errors
              var errorElement = document.getElementById('bank-account-errors');
              errorElement.textContent = '';
              // Send the token to your server.
              stripeTokenHandler(form, result.token);
            }
          });
        } else {
          console.log('User selected Plaid.');
          form.submit();
        }
      }
    });
  }

  function stripeTokenHandler(form, token) {
    form.querySelector('input#token').value = token.id;
    form.querySelectorAll('input[data-local]').forEach(function (el) {
      el.disabled = true;
    });
    console.log('Stripe data added and submitting.')
    form.submit();
  }

}

function initBraintree(key) {

  var form_pm_bt_ba = document.querySelector("form[data-processor='braintree/bank_account']");
  if (form_pm_bt_ba) registerBraintreeBankAccountForm(form_pm_bt_ba);
  var form_pm_bt_card = document.querySelector("form[data-processor='braintree/card']");
  if (form_pm_bt_card) registerBraintreeCardForm(form_pm_bt_card);


  function registerBraintreeBankAccountForm(form) {
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      var submitBtn = form.querySelector('input[type=submit]');
      disableBtn(submitBtn);

      // collect all of the user's information
      // variables formatted according to braintree expected keys
      var routingNumber = form.querySelector("#payment_method_routing_number").
        value.padStart(9, '0') ;
      var accountNumber = form.querySelector("#payment_method_account_number").value;
      var accountType = form.querySelector("#payment_method_account_type").value;
      var ownershipType = form.querySelector("#payment_method_ownership_type").value;
      var firstName = form.querySelector("#payment_method_name").value.split(" ")[0];
      var lastName = form.querySelector("#payment_method_name").value.split(" ")[1];
      var businessName = form.querySelector("#payment_method_name").value;
      var streetAddress = form.querySelector("#payment_method_street1").value;
      var locality = form.querySelector("#payment_method_city").value;
      var region = form.querySelector("#payment_method_state").value;
      var postalCode = form.querySelector("#payment_method_zip").value;
      var mandateText = form.querySelector("#mandate-text").textContent;

      var bankDetails = {
        routingNumber,
        accountNumber,
        accountType,
        ownershipType,
        billingAddress: {
          streetAddress,
          locality,
          region,
          postalCode
        }
      }
      if (ownershipType === "personal") {
        bankDetails.firstName = firstName;
        bankDetails.lastName = lastName;
      } else {
        bankDetails.businessName = businessName;
      }

      braintree.client.create({
        authorization: key
      }).then(function (clientInstance) {
        return braintree.usBankAccount.create({
          client: clientInstance
        });
      }).then(function (usBankAccountInstance) {
        return usBankAccountInstance.tokenize({
          bankDetails,
          mandateText: mandateText
        });
      }).then(function ({ nonce }) {
        braintreeTokenHandler(form, nonce);
      }).catch(function (err) {
        submitBtn.disabled = false;
        // reference to the error element
        var errorElement = document.querySelector("#bank-account-errors");
        try {
          var fullMsg = err.details.originalError.details.originalError[0].message
          var msg = fullMsg.split(' : ')[1];
          if (!msg) msg = fullMsg;
          errorElement.textContent = msg;
        } catch (error) {
          errorElement.textContent = "Please check your bank account details and formatting.";
        }
        console.error('Braintree errors: ', err);
      });
    });
  }

  function registerBraintreeCardForm(form) {
    var submitBtn = form.querySelector('input[type=submit]');

    braintree.client.create({
      authorization: key
    }, function (err, clientInstance) {
      if (err) {
        console.error(err);
        return;
      }
      createHostedFields(clientInstance);
    });
    //hosted fields on update zip
    function createHostedFields(clientInstance) {
      console.log('Creating Hosted Fields');
      enableBtn(submitBtn);

      braintree.hostedFields.create({
        client: clientInstance,
        fields: {
          number: {
            selector: '#account_number',
            placeholder: '4111 1111 1111 1111'
          },
          cvv: {
            selector: '#cvv',
            placeholder: '123'
          },
          expirationDate: {
            selector: '#expiration',
            placeholder: 'MM/YYYY'
          },
          postalCode: {
            selector: '#zip',
            placeholder: '94107'
          }
        }
      }, function (err, hostedFieldsInstance) {
        var teardown = function (event) {
          console.log('teardown');
          event.preventDefault();
          disableBtn(submitBtn);

          hostedFieldsInstance.tokenize({
            cardholderName: document.querySelector("#payment_method_name").value,
            fieldsToTokenize: ['number', 'expirationDate', 'cvv', 'postalCode']
          }, function (err, payload) {
            if (err) {
              enableBtn(submitBtn);
              // reference to the error element
              var errorElement = document.querySelector("#card-errors");
              try {
                var badFields = err.details.invalidFieldKeys;
                var list = badFields.join(', ');
                errorElement.textContent = `Please check your card details (${list}) and try agin.`;
              } catch (error) {
                errorElement.textContent = "Please check your card details and try agin.";
              }
              console.error('Braintree errors: ', err);
              // clear the card fields
              hostedFieldsInstance.teardown(function () {
                createHostedFields(clientInstance);
                form.removeEventListener('submit', teardown, false);
              });
              return;
            }
            braintreeTokenHandler(form, payload.nonce);
          });
        };
        form.addEventListener('submit', teardown, false);
      });
    }
  }

  function braintreeTokenHandler(form, nonce) {
    form.querySelector('input#token').value = nonce;
    form.querySelectorAll('input[data-local]').forEach(function (el) {
      el.disabled = true;
    });
    console.log('Braintree data added and submitting.')
    form.submit();
  }

}

function disableBtn(btn) {
  console.log('Disabling submit.');
  btn.disabled = true;
  //btn.setAttribute('data-disable-with', btn.value);
  window.Rails.disableElement(btn);
}

function enableBtn(btn) {
  console.log('Enabling submit.');
  btn.disabled = false;
  window.Rails.enableElement(btn);
  //btn.removeAttribute('data-disable-with');
}

console.log('pm');
