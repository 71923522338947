import {
  ready,
  addClass, removeClass,
} from "dom-boilerplate";

ready(function() {
  var newModal = document.querySelector('.modal#new-receipt');

  var newReceipt = document.querySelector('a[href="#new-receipt"]');
  if (newReceipt) {
    newReceipt.addEventListener('click', function() {
      addClass(newModal, 'is-active');
    }, false);

  }
  var closeNewReceipt = document.querySelector('#new-receipt.modal button.delete');
  if (closeNewReceipt) closeNewReceipt.addEventListener('click', function() {
    removeClass(newModal, 'is-active');
  }, false);
});
