import {
  ready,
  addClass, removeClass,
} from "dom-boilerplate";

ready(function() {
  var editModal = document.querySelector('.modal#edit-governance');

  var editGovernance = document.querySelector('a[href="#edit-governance"]');
  if (editGovernance) {
    editGovernance.addEventListener('click', function() {
      addClass(editModal, 'is-active');
    }, false);

  }
  var closeEditGovernance = document.querySelector('#edit-governance.modal button.delete');
  if (closeEditGovernance) closeEditGovernance.addEventListener('click', function() {
    removeClass(editModal, 'is-active');
  }, false);
});
