import map from 'lodash/map';
import debounce from 'lodash/debounce';

import Selectr from '../selectr.js'

import {
  ready,
  GET,
  page,
} from "dom-boilerplate";

window.App.adminAddInitSelectr = function(target_prefix) {

  var fundSelectr = new Selectr(
    document.querySelector(target_prefix + ' select.fund-select'),
    {
      searchable: true
    }
  );

  fundSelectr.on('selectr.init', function(options) {
    GET('/projects?paginate=true', {}).then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data)
      for (var i = 0; i < data.data.length; i++) {
        var obj = data.data[i];
        var value = obj.id;
        var text = obj.name_and_fe_ref;
        //fundSelectr.remove(value); results in an undefined

        var values = map(fundSelectr.data, function(o) { return o.value });
        if (fundSelectr.selectedValue)
          values.push(Number(fundSelectr.selectedValue));
        var exists = values.indexOf(value) > -1;

        //debugger
        if (!exists) fundSelectr.add({
          value: value,
          text: text,
        });
      }
    });
    var that = this;

    this.input.addEventListener('keydown', debounce(function(e) {
      page(GET, "/projects?paginate=true&term="+this.value, {}).
        then(function (data) {
          // NOTE: data from page is not nested like above
          //console.log(data)
          for (var i = 0; i < data.length; i++) {
            var obj = data[i];
            var value = obj.id;
            var text = obj.name_and_fe_ref;
            //fundSelectr.remove(value); results in an undefined
            var values = map(that.data, function(o) { return o.value });
            var exists = values.indexOf(value) > -1
            if (!exists) that.add({
              value: value,
              text: text,
            });
          }
          that.search();
        });
    }, 250, {'maxWait': 1000}))
  });

/*
  fundSelectr.on('selectr.select', function(option) {
    console.log('select', option);
    GET('/legacy-funds/' + option.value, {}).then(function(response) {
      return response.json();
    }).then(function(data) {
      if (data.designations && data.designations.length > 0) {
        var oldValues = map(designationSelectr.data, function(o) {
          return o.value;
        });
        designationSelectr.remove(oldValues);

        var values = map(data.designations, function(o) {
          return {
            value: o.legacy_id,
            text: o.name
          };
        });
        designationSelectr.add(values);

      } else { // no designations
        designationSelectr.removeAll();
      }
      fundSelectr.clear();
    });
  });
*/

/*
  var designationSelectr = new Selectr(
    document.querySelector(target_prefix+' select.designation-select'),
    {
      searchable: true
    }
  );
*/

};

ready(function() {
  var selectr_selectors = [
    '.modal#add-donation-user',
    '.modal#add-plan-user',
    '.modal#edit-plan',
    '.modal#edit-donation',
  ];

  selectr_selectors.forEach(function(selector) {
    if (document.querySelector(selector)) App.adminAddInitSelectr(selector);
  });
});
