import {
  ready,
  addClass, removeClass,
} from "dom-boilerplate";

ready(function() {
  var editModal = document.querySelector('.modal#edit-plan');

  var editPlan = document.querySelector('a[href="#edit-plan"]');
  if (editPlan) {
    editPlan.addEventListener('click', function() {
      addClass(editModal, 'is-active');
    }, false);

  }
  var closeEditPlan = document.querySelector('#edit-plan.modal button.delete');
  if (closeEditPlan) closeEditPlan.addEventListener('click', function() {
    removeClass(editModal, 'is-active');
  }, false);
});
