import {
  ready,
  addClass, removeClass,
} from "dom-boilerplate";

ready(function() {
  var editDetailsModal = document.querySelector('.modal#edit-details');

  var editDetailsLink = document.querySelector('a[href="#edit-details"]');
  if (editDetailsLink) {
    editDetailsLink.addEventListener('click', function() {
      addClass(editDetailsModal, 'is-active');
    }, false);
  }
  var closeEditDetails = document.querySelector('#edit-details.modal button.delete');
  if (closeEditDetails) {
    closeEditDetails.addEventListener('click', function() {
      removeClass(editDetailsModal, 'is-active');
    }, false);
  }

});
