import {
  ready,
  //addClass, removeClass,
} from "dom-boilerplate";

ready(function() {

  // Elements with data-clip attributes get a click handler that copies the
  // value of the data-clip if present or the innerText if not.
  //
  var clipElements = document.querySelectorAll('[data-clip]');
  clipElements.forEach(function(element) {
    element.addEventListener('click', function(event) {
      var clipText = element.getAttribute('data-clip');
      if (clipText) {
        copyToClipboard(clipText);
      } else {
        copyToClipboard(element.innerText);
      }
      event.preventDefault();
    }, false);
  });

  // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  // Could be an async function
  // async function copyToClipboard(text) {
  //   try {
  //     await navigator.clipboard.writeText(text);
  //     console.log(`Copied "${text}" to clipboard`);
  //   } catch (error) {
  //     console.error(`Error copying "${text}" to clipboard:`, error);
  //   }
  // }
  function copyToClipboard(text) {
    if (navigator.clipboard) {
      const item = new ClipboardItem({ "text/plain": new Blob([text], { type: "text/plain" }) });
      navigator.clipboard.write([item])
        .then(() => {
          console.log(`Copied "${text}" to clipboard`);
        })
        .catch((error) => {
          console.error(`Error copying "${text}" to clipboard:`, error);
        });
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        console.log(`Copied "${text}" to clipboard`);
      } catch (error) {
        console.error(`Error copying "${text}" to clipboard:`, error);
      }
      document.body.removeChild(textarea);
    }
  }

});