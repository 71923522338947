import {
  ready,
  addClass, removeClass,
} from "dom-boilerplate";

ready(function() {
  var editModal = document.querySelector('.modal#edit-user');

  var editUser = document.querySelector('a[href="#edit-user"]');
  if (editUser) {
    editUser.addEventListener('click', function() {
      addClass(editModal, 'is-active');
    }, false);

  }
  var closeEditUser = document.querySelector('#edit-user.modal button.delete');
  if (closeEditUser) closeEditUser.addEventListener('click', function() {
    removeClass(editModal, 'is-active');
  }, false);


  var addPMCardModal = document.querySelector('.modal#add-pm-card');
  var addPMCard = document.querySelector('a[href="#add-pm-card"]');
  if (addPMCard) {
    addPMCard.addEventListener('click', function() {
      addClass(addPMCardModal, 'is-active');
    }, false);

  }
  var closeAddPMCard = document.querySelector('#add-pm-card.modal button.delete');
  if (closeAddPMCard) closeAddPMCard.addEventListener('click', function() {
    removeClass(addPMCardModal, 'is-active');
  }, false);

  var addPMBankAccountModal = document.querySelector('.modal#add-pm-bank-account');
  var addPMBankAccount = document.querySelector('a[href="#add-pm-bank-account"]');
  if (addPMBankAccount) {
    addPMBankAccount.addEventListener('click', function() {
      addClass(addPMBankAccountModal, 'is-active');
    }, false);

  }
  var closeAddPMBankAccount = document.querySelector('#add-pm-bank-account.modal button.delete');
  if (closeAddPMBankAccount) closeAddPMBankAccount.addEventListener('click', function() {
    removeClass(addPMBankAccountModal, 'is-active');
  }, false);

  var addPMPaypalModal = document.querySelector('.modal#add-pm-paypal');
  var addPMPaypal = document.querySelector('a[href="#add-pm-paypal"]');
  if (addPMPaypal) {
    addPMPaypal.addEventListener('click', function() {
      addClass(addPMPaypalModal, 'is-active');
    }, false);

  }
  var closeAddPMPaypal = document.querySelector('#add-pm-paypal.modal button.delete');
  if (closeAddPMPaypal) closeAddPMPaypal.addEventListener('click', function() {
    removeClass(addPMPaypalModal, 'is-active');
  }, false);

  var addDonationModal = document.querySelector('.modal#add-donation-user');

  var addDonationUser = document.querySelector('a[href="#add-donation-user"]');
  if (addDonationUser) {
    addDonationUser.addEventListener('click', function() {
      addClass(addDonationModal, 'is-active');
    }, false);
  }

  var closeAddDonationUser = document.querySelector('#add-donation-user.modal button.delete');
  if (closeAddDonationUser) {
    closeAddDonationUser.addEventListener('click', function() {
      removeClass(addDonationModal, 'is-active');
    }, false);
  }

  var addPlanModal = document.querySelector('.modal#add-plan-user');

  var addPlanUser = document.querySelector('a[href="#add-plan-user"]');
  if (addPlanUser) {
    addPlanUser.addEventListener('click', function() {
      addClass(addPlanModal, 'is-active');
    }, false);
  }

  var closeAddPlanUser = document.querySelector('#add-plan-user.modal button.delete');
  if (closeAddPlanUser) {
    closeAddPlanUser.addEventListener('click', function() {
      removeClass(addPlanModal, 'is-active');
    }, false);
  }
});
