// jsbundling entrypoint
// Entry point for the build script in your package.json

import Rails from '@rails/ujs'
Rails.start()

import 'trix'
import '@rails/actiontext'

import LocalTime from "local-time"
LocalTime.start()

// This is the legacy donation_service, but converted to ES6
import './donation_service'
